<template>
    <b-container id="mode">
        <div v-if="!error" class="spinner">
            <b-spinner v-if="!success" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            <a-icon v-else style="font-size: 3rem; color: #b7eb8f" type="check-circle" />
        </div>
        <h1 ref="statusText">
            กำลังรอข้อมูลจาก Shopee Pay
        </h1>

        <p class="detail">
            <strong class="text-xl">
                รายละเอียดการทำรายการ
                <button class="btn" @click="copyContactDetail">คัดลอก</button>
            </strong>
            <br />
            <ul>
                <li>เลขอ้างอิง: {{ $route.params.reference_id }}</li>
                <li>เวลาที่ทำรายการ: {{ datetime }}</li>
                <li v-if="success">Transaction SN: {{ transaction_sn }}</li>
            </ul>
        </p>
    
        <!-- Display if transaction not found -->
        <div v-if="error" class="contactStaff">
            <a class="contactbtn btn" href="https://www.facebook.com/NovelRealm/" target="_blank">ติดต่อเจ้าหน้าที่</a>
        </div>

        <!-- Hidden area for copy -->
        <textarea class="hidden" v-model="contactDetail" ref="copyArea"></textarea>
    </b-container>
</template>

<script>
import axios from 'axios'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {
    data () {
        return {
            error: false,
            success: false,
            datetime: '',
            returnRound: 0,
            validateInterval: ()=>{},
            contactDetail: '',
            transaction_sn: ''
        }
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            mode: 'GET_MODE'
        })
    },
    methods:{
        ...mapActions(['SET_MODE']),
        async validatePaymentStatus() {
            // do only didn't error
            if(this.error === false){
                // read reference from route params
                const referenceId = this.$route.params.reference_id
                let response

                try{
                    response = (await axios.get(`https://api2.novelrealm.com/module/payment&action=payment_shopee_status&reference_id=${referenceId}`)).data
                }
                catch(e){
                    response = {
                        status: "500",
                        description: "axios recived error"
                    }
                }
                // if found transaction api will return 200
                if(response.status === "200"){
                    if(response.reference_status === "Success via shopee"){
                        this.$refs.statusText.innerText = "ทำรายการสำเร็จ"
                        this.transaction_sn = response.transaction.transaction_sn
                        this.success = true
                    }
                    else{
                        // still pending -> update retry round
                        if(this.returnRound === 6){
                            this.$refs.statusText.innerText = "ไม่สามารถยืนยันสถานะกับ ShopeePay ได้"
                            this.error = true
                        }
                        else{
                            this.returnRound = this.returnRound + 1
                            this.$refs.statusText.innerText = "กำลังรอข้อมูลจาก Shopee Pay (ลองใหม่ครั้งที่ "+this.returnRound+")"
                        }
                    }
                }
                else{
                    this.$refs.statusText.innerText = "ไม่พบรายการในระบบ"
                    this.error = true
                }
            }
        },
        copyContactDetail () {
            this.$refs.copyArea.select()
            this.$refs.copyArea.setSelectionRange(0, 99999); /* For mobile devices */
            navigator.clipboard.writeText(this.$refs.copyArea.value);
            this.$notification.open({
                message: 'คัดลอกข้อความสำเร็จ',
                icon: <a-icon type="copy" style="color: #f5d087" />,
            });
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            if(document.getElementById('mode').classList.contains('darkmode')){
                document.getElementById('mode').classList.remove('darkmode')
            }
        },
        darkmode() {
            if(!document.getElementById('mode').classList.contains('darkmode')){
                document.getElementById('mode').classList.add('darkmode')
            }
        }
    },
    mounted: function () {
        this.checkMode()
        // set default datetime and copyDetail
        const currentdate = new Date();
        this.datetime = "วันที่ " + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/"
                + currentdate.getFullYear() + " เวลา "
                + currentdate.getHours() + ":"
                + ("0"+currentdate.getMinutes()).slice(-2)

        this.contactDetail = " \
รายละเอียดการทำรายการ \n \
\n \
- เลขอ้างอิง: "+ this.$route.params.reference_id +" \n \
- เวลาที่ทำรายการ: "+ this.datetime +" \
        "
        // validate method
        // exec validate one time
        this.validatePaymentStatus()
        // after that re exec every 5 secs
        this.validateInterval = setInterval(
            () => {
                this.validatePaymentStatus()
            }
        , 5000)
    },
    destroyed () {
        clearInterval(this.validateInterval)
    }
};
</script>

<style scoped>
#mode{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 2% 2% 2%;
    background: #fff;
}

.spinner{
    margin-bottom: 15px;
}

.btn{
    font-size: 14px;
    font-weight: bold;
    background-color: #f5d087;
    color: white;
    border: none;
    border-radius: 10px;
}

.contactbtn {
    margin-top: 10px;
}

.contactStaff{
    display: flex;
    width :80%;
    align-items: center;
    flex-direction: column;
}

.detail{
    background-color: rgb(243, 243, 243);
    margin-top: 5px;
    padding: 2rem;
}

.contactStaff .detail{
    width: 100%;
}

.text-xl{
    font-size: 1.3rem;
    display: block;
}

.hidden{
    display:none;
}

/* darkmode */
.darkmode{
    background-color: #35363A !important;
}
.darkmode .detail{
    background-color: #4f5157 !important;
}
.darkmode *{
    color: #fff;
}
</style>